import React, { useState } from "react";

// import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
// import ReCAPTCHA from "react-google-recaptcha";
import * as S from "../components/Forms/styled";
// import { useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { Link, navigate } from "gatsby";

// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&");
// }

const TalentJobPage = ({ location }) => {
  // const { register, errors, handleSubmit, formState } = useForm({
  //   mode: "onChange",
  // });
  // const backLink = typeof document !== `undefined` ? document.referrer : "/";
  // const [recaptchaKey, setRecaptchaKey] = useState("");
  // const [buttonData, setButtonData] = useState({
  //   text: "Enviar",
  //   disabled: false,
  // });
  // useEffect(async () => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://d335luupugsy2.cloudfront.net/js/loader-scripts/863d8ad1-c9a2-451d-b5b9-6c7a47d67811-loader.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, []);

  // const captchaChange = (value) => {
  //   console.log(register);
  //   setRecaptchaKey(value);
  // };
  // const contactSubmit = (data) => {
  //   setButtonData({
  //     text: "Enviando...",
  //     disabled: true,
  //   });
  //   setTimeout(() => {
  //     fetch("/", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //       body: encode({
  //         "form-name": "talent-specific-job-form",
  //         ...data,
  //       }),
  //     })
  //       .then(() => (window.location.href = "/candidatura-enviada"))
  //       .catch((error) =>
  //         alert("Erro ao enviar os dados! Tente novamente mais tarde.")
  //       );
  //   }, 5000);
  // };

  return (
    // <ModalRoutingContext.Consumer>
    //   {({ modal, closeTo }) => (
    //     <S.FormWrapper>
    //       <S.FormTitle>{`Candidatar-se para a vaga${
    //         location.state ? ": " + location.state.jobTitle : ""
    //       }`}</S.FormTitle>

    //       <S.Form
    //         method="POST"
    //         data-netlify="true"
    //         autoComplete="off"
    //         name="talent-specific-job-form"
    //         onSubmit={handleSubmit(contactSubmit)}
    //       >
    //         <input
    //           type="hidden"
    //           name="form-name"
    //           value="talent-specific-job-form"
    //         />
    //         <S.FormInputWrapperHalf className="field">
    //           <span>
    //             <S.FormInputLabel className="label" htmlFor="name">
    //               Nome completo
    //             </S.FormInputLabel>
    //             <S.FormInput
    //               className="input"
    //               id="name"
    //               type="text"
    //               name="name"
    //               placeholder="Nome completo"
    //               ref={register({
    //                 required: true,
    //                 minLength: 5,
    //                 maxLength: 255,
    //               })}
    //             />
    //             <p className="help is-danger">
    //               {errors.name?.type === "required" && "Preencha este campo"}
    //               {errors.name?.type === "minLength" &&
    //                 "Insira seu nome completo"}
    //               {errors.name?.type === "maxLength" &&
    //                 "Limite de caracteres excedido"}
    //             </p>
    //           </span>
    //           <span>
    //             <S.FormInputLabel className="label" htmlFor="email">
    //               E-mail
    //             </S.FormInputLabel>
    //             <S.FormInput
    //               className="input"
    //               id="email"
    //               ref={register({ required: true, maxLength: 255 })}
    //               type="email"
    //               name="email"
    //               placeholder="E-mail"
    //             />
    //             <p className="help is-danger">
    //               {errors.email?.type === "required" && "Preencha este campo"}
    //               {errors.email?.type === "maxLength" &&
    //                 "Limite de caracteres excedido"}
    //             </p>
    //           </span>
    //         </S.FormInputWrapperHalf>

    //         <S.FormInputWrapperHalf className="field">
    //           <span>
    //             <S.FormInputLabel className="label" htmlFor="linkedin">
    //               LinkedIn
    //             </S.FormInputLabel>
    //             <S.FormInput
    //               className="input"
    //               id="linkedin"
    //               type="text"
    //               name="linkedin"
    //               placeholder="https://www.linkedin.com/in/usuario/"
    //               ref={register({
    //                 required: true,
    //                 maxLength: 1048,
    //                 pattern: new RegExp(
    //                   "http(s)?://([w]+.)?linkedin.com/in/[A-z0-9_-]+/?"
    //                 ),
    //               })}
    //             />
    //             <p className="help is-danger">
    //               {errors.linkedin?.type === "required" &&
    //                 "Preencha este campo"}

    //               {errors.linkedin?.type === "pattern" &&
    //                 "LinkedIn inválido. Insira o link completo"}
    //             </p>
    //           </span>
    //           <span>
    //             <S.FormInputLabel className="label" htmlFor="phone-number">
    //               Telefone
    //             </S.FormInputLabel>
    //             <S.FormInputWithMask
    //               className="input"
    //               mask={"(99) 99999-9999"}
    //               maskChar={null}
    //               id="phone-number"
    //               type="tel"
    //               name="phone-number"
    //               placeholder="Telefone"
    //               ref={register({
    //                 required: true,
    //                 minLength: 15,
    //                 pattern: /\(\d{2}\)\s\d{4,5}\-\d{4}/i,
    //               })}
    //             />
    //             <p className="help is-danger">
    //               {errors["phone-number"]?.type === "required" &&
    //                 "Preencha este campo"}
    //               {errors["phone-number"]?.type === "minLength" &&
    //                 "Número de telefone inválido"}
    //               {errors["phone-number"]?.type === "pattern" &&
    //                 "Número de telefone inválido"}
    //             </p>
    //           </span>
    //         </S.FormInputWrapperHalf>

    //         <S.FormInputWrapperFull>
    //           <S.FormInput
    //             className="input"
    //             id="job-url"
    //             type="hidden"
    //             name="job-url"
    //             placeholder="slug"
    //             ref={register({ required: true })}
    //             value={backLink || "Banco de Talentos"}
    //           />
    //         </S.FormInputWrapperFull>

    //         {/* <S.FormInputWrapperFull>
    //           <S.FormInputLabel className="label" htmlFor="file-doccument">
    //             Anexar CV (.doc ou .pdf)
    //           </S.FormInputLabel>
    //           <S.FormInput
    //             className="input"
    //             id="file-doccument"
    //             type="file"
    //             name="file-doccument"
    //             accept="application/msword, application/pdf"
    //             ref={register({ required: true })}
    //           />
    //           <p className="help is-danger">
    //             {errors["file-doccument"]?.type === "required" &&
    //               "Selecione um arquivo"}
    //           </p>
    //         </S.FormInputWrapperFull> */}

    //         <S.FormInputWrapperFull className="field">
    //           <span>
    //             <label className="checkbox">
    //               Ao informar meus dados eu concordo em receber comunicações e
    //               com a{" "}
    //               {
    //                 <Link to="/politica-de-privacidade">
    //                   Política de privacidade
    //                 </Link>
    //               }
    //               .
    //             </label>
    //           </span>
    //         </S.FormInputWrapperFull>

    //         <S.FormInputWrapperFull>
    //           <ReCAPTCHA
    //             sitekey="6LfaVEcaAAAAAEfbn35Pw1wNeIXBgNTy0kPDiV0S"
    //             onChange={captchaChange}
    //           />
    //           <span>
    //             <S.FormInput
    //               className="input"
    //               id="recaptcha"
    //               type="hidden"
    //               name="recaptcha"
    //               placeholder="ReCAPTCHA"
    //               ref={register({ required: false })}
    //               value={recaptchaKey}
    //             />
    //             <p className="help is-danger">
    //               {errors.recaptcha?.type === "required" &&
    //                 "Complete o ReCAPTCHA antes de prosseguir"}
    //             </p>
    //           </span>
    //         </S.FormInputWrapperFull>
    //         <S.FormActionsWrapper>
    //           <S.FormLink to={backLink} className="button">
    //             Voltar
    //           </S.FormLink>
    //           <S.FormSubmit
    //             className="button is-primary"
    //             disabled={
    //               buttonData.disabled ||
    //               !formState.isValid ||
    //               recaptchaKey === ""
    //             }
    //             type="submit"
    //           >
    //             {buttonData.text}
    //           </S.FormSubmit>
    //         </S.FormActionsWrapper>
    //       </S.Form>
    //     </S.FormWrapper>
    //   )}
    // </ModalRoutingContext.Consumer>
    <h1>vaga candidatar</h1>
  );
};

export default TalentJobPage;
