import styled from "styled-components";
import media from "styled-media-query";
// import { Link } from "gatsby-plugin-modal-routing";
import InputMask from "react-input-mask";

export const FormWrapper = styled.section`
  padding: 2rem var(--default-padding-desktop);
  ${media.lessThan("medium")`
    padding: 1rem;
  `}
`;
export const FormTitle = styled.h1`
  font-size: var(--medium-title-size);
  font-weight: bolder;
  line-height: 5rem;
  margin-bottom: 1rem;
  text-align: center;
  ${media.lessThan("large")`
    font-size: var(--small-title-size);
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
  `}
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const FormInputWrapperFull = styled.div`
  margin-bottom: 1rem;
`;
export const FormInputWrapperHalf = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}
`;
export const FormInputLabel = styled.label``;
export const FormInput = styled.input``;
export const FormInputWithMask = styled(InputMask)``;

export const FormInputTextArea = styled.textarea``;

export const FormActionsWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
`;
export const FormSubmit = styled.button``;
//export const FormLink = styled(Link)``;